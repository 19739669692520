import { createApp } from 'vue';
import VueLoading from 'vue-loading-overlay';
//import Vuetify from 'vuetify';
//import 'vuetify/dist/vuetify.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import App from './App.vue';

const app = createApp(App);
app.use(VueLoading);
//app.use(Vuetify);
app.mount('#app');
