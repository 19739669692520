import { QuestionModel, QuestionType, ChoiceOption } from '@ditdot-dev/vue-flow-form';

// Constants
const MENU = JSON.parse(process.env.VUE_APP_MENU);
const MAP = JSON.parse(process.env.VUE_APP_MAP);
const BASE_URL = process.env.VUE_APP_BASE_IMAGES_URL;
const DICTIONARY = Object.assign(
  {},
  ...MAP.map((i) => ({
    [i.style ?? i]: toUrl(i)
  }))
);
const ROOT = 'styles';
const KEYS = Object.keys(MENU[ROOT]);

// Utils
function unspacify(s) {
  return s?.replaceAll(' ', '-');
}
const spacify = (s) => s.replaceAll('-', ' ').replaceAll('_', ' ');
const titleCase = (s) => s[0].toUpperCase() + s.slice(1).toLowerCase();

const getFirstProperty = (obj) => (!obj ? undefined : obj[Object.keys(obj)[0]]);

const getFirstArrayItem = (obj) => {
  if (!obj) return undefined;
  if (Array.isArray(obj)) return obj[0];
  return getFirstArrayItem(getFirstProperty(obj));
};

const findByKey = (obj, key) => {
  if (key in obj) return obj[key];
  for (const n of Object.values(obj)
    .filter(Boolean)
    .filter((v) => typeof v === 'object')) {
    const found = findByKey(n, key);
    if (found) return found;
  }
};

function toUrl(i) {
  return `${BASE_URL}${i.url ?? unspacify(i.style ?? i)}.png`;
}

const getUrl = (value) =>
  DICTIONARY[value] ??
  DICTIONARY[getFirstArrayItem(MENU[value])] ??
  DICTIONARY[getFirstArrayItem(findByKey(MENU, value))];

// Map functions
const toChoiceOption = (value) =>
  new ChoiceOption({
    value,
    label: titleCase(value),
    imageSrc: getUrl(value)
  });

const toChoiceOptions = (value) =>
  value instanceof Array ? value : Object.keys(value).map(toChoiceOption);

const toJump = (value) =>
  value instanceof Array
    ? value.reduce((acc, choice) => ({ ...acc, [choice.value]: ROOT }), {})
    : Object.keys(value).reduce(
        (acc, question) => ({ ...acc, [question]: question }),
        {}
      );

const toQuestionModel = (key, value) =>
  new QuestionModel({
    id: key,
    title: titleCase(spacify(key)),
    type: QuestionType.MultiplePictureChoice,
    nextStepOnAnswer: true,
    required: true,
    options: toChoiceOptions(value),
    jump: toJump(value)
  });

// Building questions
const secretQuestion = new QuestionModel({
  id: '_secret',
  title: 'Secret',
  type: QuestionType.Text,
  nextStepOnAnswer: true,
  required: true,
  answer: localStorage.getItem('secret')
});

const subjectQuestion = new QuestionModel({
  id: '_subject',
  title: 'What do you want to imagine?',
  type: QuestionType.Text,
  nextStepOnAnswer: true,
  required: true
});

const buildQuestions = () => {
  const questions = [];

  JSON.parse(JSON.stringify(MENU), function (key, value) {
    if (this instanceof Array && typeof value === 'string') {
      return toChoiceOption(value);
    } else if (key) {
      const question = toQuestionModel(key, value);
      if (key === ROOT) questions.unshift(question);
      else questions.push(question);
      return question;
    }
    return value;
  });

  questions.unshift(subjectQuestion);
  if (secretQuestion.answer) questions.push(secretQuestion);
  else questions.unshift(secretQuestion);
  return questions;
};

export const questions = buildQuestions();

// Parsing answers
const dive = (o, key, i) => (o[o[key]] && i-- > 0 ? dive(o, o[key]) : o[key]);

const getSecret = (questions) => questions.filter((q) => q.id === '_secret')?.[0]?.answer;

const getSubject = (questions) =>
  questions.filter((q) => q.id === '_subject')?.[0]?.answer;

const getDeepAnswers = (questions) => {
  const answers = questions
    .filter((q) => !!q.answer)
    .reduce((acc, { id, answer }) => ({ ...acc, [id]: answer }), {});
  const deepAnswers = Object.entries(answers).reduce(
    (acc, [key]) => ({
      ...acc,
      ...(KEYS.includes(key) && { [key]: dive(answers, key, 5) })
    }),
    {}
  );
  const subject = getSubject(questions);
  return { ...(subject && { _subject: subject }), ...deepAnswers };
};

export const getAnswers = (questions) => {
  const answers = getDeepAnswers(questions);
  const secret = getSecret(questions);
  return { answers, secret };
};
