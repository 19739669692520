<template>
  <div>
    <flow-form
      v-if="!loading && !finished"
      ref="flowform"
      v-bind:questions="questions"
      v-bind:progressbar="false"
      v-bind:navigation="false"
    >
      <div class="submit">
        <button
          v-if="!loading && !!prompt"
          class="o-btn-action"
          type="submit"
          v-on:click.prevent="onSubmit()"
          href="#"
          aria-label="Press to submit"
        >
          <span>Submit</span>
        </button>
        <p
          title="Copy to clipboard"
          class="prompt"
          v-on:click.prevent="copyToClipBoard(prompt)"
        >
          {{ prompt }}
        </p>
      </div>
    </flow-form>
    <div class="container">
      <img :cover="true" v-show="loading || finished" :src="url" />
    </div>
  </div>
</template>

<script>
import { FlowForm } from '@ditdot-dev/vue-flow-form';
import { useLoading } from 'vue-loading-overlay';
import { submit, subscribe } from './external';
import { store } from './store';
import { questions, getAnswers } from './questions';

const $loading = useLoading();
export default {
  name: 'PromptBuilder',
  components: {
    FlowForm
  },
  data() {
    return {
      questions
    };
  },
  computed: {
    loading() {
      return store.loading;
    },
    finished() {
      return store.finished;
    },
    url() {
      return store.url;
    },
    prompt() {
      const { answers } = getAnswers(this.questions);
      const prompt = Object.keys(answers)
        .map((k) => answers[k])
        .join(', ');
      return prompt ? `/imagine prompt:${prompt}` : undefined;
    }
  },
  async mounted() {
    await subscribe();
  },
  methods: {
    copyToClipBoard(text) {
      navigator.clipboard.writeText(text);
    },
    async onSubmit() {
      const { answers, secret } = getAnswers(this.$refs.flowform.questions);
      const loader = $loading.show({ backgroundColor: '#080808' });
      store.loading = true;

      await submit(secret, answers);

      const hideLoader = () => {
        if (!store.loading) loader.hide();
        else setTimeout(hideLoader, 1000);
      };

      setTimeout(hideLoader, 15000);
    }
  }
};
</script>

<style>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.container img {
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
}

.prompt {
  cursor: copy;
  margin-left: 2rem;
}
.submit {
  display: flex;
}
</style>
