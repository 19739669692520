import { createClient } from '@supabase/supabase-js';
import { store } from './store';

const TABLE = process.env.VUE_APP_SUPABASE_TABLE;
const CHAT = process.env.VUE_APP_CHAT_ID;

const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL,
  process.env.VUE_APP_SUPABASE_KEY
);

export const subscribe = async () => {
  const { data } = await supabase.from(TABLE).select(`url`).eq('id', CHAT).single();
  if (data?.url) store.url = data.url;
  supabase
    .from(`${TABLE}:id=eq.${CHAT}`)
    .on('UPDATE', (payload) => {
      store.url = payload.new.url;
      if (payload.new.url.endsWith('.png') && !!payload.new.message_id) {
        store.loading = false;
        store.finished = true;
      }
    })
    .subscribe();
};

export const submit = async (secret, body) =>
  await fetch(`${process.env.VUE_APP_SUBMIT_URL}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({ Authorization: `${secret}` })
  });
